import { parseJwt } from "../../../utils/utils";


export enum localStorageKeys {
    VARIABLE_MANAGER = "VARIABLE_MANAGER",
    USER_SURVEY_FLOW = "user-flow",
    SURVEY_TOKEN = 'token',
    SURVEY_ID = 'surveyId',
    REV_DATA = 'revIdData',
    _GRE_CAPTCHA = '_grecaptcha',
    FLOW_REDIRECT = 'flowredirect',
    ANONYMOUS_SURVEY_STATE = 'anonymous-survey-state',
}

interface LocalStorageData {
    takerId: string;
}

export const generateLocalStorageKey = (key: localStorageKeys, data: LocalStorageData): string => {
    return `${data.takerId}_${key}`;
};

export const getLocalStorageItem = (key: localStorageKeys, data: LocalStorageData): string | null => {
    const localStorageKey = generateLocalStorageKey(key, data);
    return localStorage.getItem(localStorageKey);
};

export const setLocalStorageItem = (key: localStorageKeys, value: string, data: LocalStorageData): void => {
    const localStorageKey = generateLocalStorageKey(key, data);
    localStorage.setItem(localStorageKey, value);
};

export const removeLocalStorageItem = (key: localStorageKeys, data: LocalStorageData): void => {
    const localStorageKey = generateLocalStorageKey(key, data);
    localStorage.removeItem(localStorageKey);
};

export const clearLocalStorageByPattern = (takerId: string): void => {
    const pattern = `${takerId}_`;
    const keysToRemove: string[] = [];
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && key.startsWith(pattern)) {
            keysToRemove.push(key);
        }
    }
    keysToRemove.forEach(key => localStorage.removeItem(key));
    // Now remove taker id from takerids array
    const takerIds: string[] = JSON.parse(localStorage.getItem('takerIds') as string) || [];
    const ids = [...takerIds.filter(id => id !== takerId)];
    localStorage.setItem("takerIds", JSON.stringify(ids));
};

export const isTokenExpired = (jwtPayload: any): boolean => {
    const expirationTime = jwtPayload.exp * 1000;
    const currentTime = Date.now();
    return expirationTime < currentTime;
}

export const getLastTokenFromSurveyStorage = (latestToken: string): string => {
    const { survey } = parseJwt(latestToken);
    const takerIds: string[] = JSON.parse(localStorage.getItem('takerIds') as string) || [];
    if (!takerIds.length) {
        localStorage.setItem("takerIds", JSON.stringify([survey.takerId]));
        return latestToken;
    }
    const takerId = takerIds[0];
    
    if (!survey.allowSurveyRestart) {
        return latestToken;
    }

    const previousJWT = getLocalStorageItem(localStorageKeys.SURVEY_TOKEN, { takerId: takerId }) || '';
    if (!previousJWT) {
        return latestToken;
    }
    const payload = parseJwt(previousJWT);
    const isTokenExp = isTokenExpired(payload);
    //remove previous takerid from localstorage and set new takerid
    const ids = [...takerIds.filter(takerid => takerid !== takerId), survey.takerId];
    // if token expired then removed from local storage and continue with current token.
    if (isTokenExp) {
        localStorage.setItem("takerIds", JSON.stringify(ids));
        return latestToken;
    }
    const isValidSurvey = survey.designId === payload.survey.designId && survey.orgId === payload.survey.orgId;
    if (isValidSurvey && payload.survey.allowSurveyRestart) return previousJWT;
    localStorage.setItem("takerIds", JSON.stringify(ids));
    return latestToken;
} 

export const getAnonymousSurveyState = (token: string): string => {
    const key = `${token}_${localStorageKeys.ANONYMOUS_SURVEY_STATE}`;
    const state = localStorage.getItem(key);
    localStorage.removeItem(key); //removed key once fetched
    if (!state) return '';
    const { survey } = parseJwt(state);
    return survey?.takerId || '';
};

export const setAnonymousSurveyState = (token: string, jwt: string): void => {
    localStorage.setItem(`${token}_${localStorageKeys.ANONYMOUS_SURVEY_STATE}`, jwt);
};

export const clearSurveyStateOnCompletion = (token: string | undefined, takerId: string): void => {
    console.log("token", token);
    localStorage.removeItem(`${token}_${localStorageKeys.ANONYMOUS_SURVEY_STATE}`);
    localStorage.removeItem(`${takerId}_${localStorageKeys.SURVEY_TOKEN}`);  
    localStorage.removeItem(`${takerId}_${localStorageKeys.SURVEY_ID}`);
    localStorage.removeItem(`${takerId}_${localStorageKeys.REV_DATA}`);
    localStorage.removeItem(`${takerId}_${localStorageKeys.VARIABLE_MANAGER}`);
    localStorage.removeItem(`${takerId}_${localStorageKeys.USER_SURVEY_FLOW}`);
    localStorage.removeItem(`${takerId}_${localStorageKeys.FLOW_REDIRECT}`);
}