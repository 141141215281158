import "./Unsubscribe.scss";
import { useLocation } from "react-router-dom";

const UnsubscribeSuccess = () => {
const location = useLocation();
const imageUrl = location.state?.imageUrl 
  return (
    <div className="container">
      <div className="inner-container">
      <img src={imageUrl} alt="Unsubscribe" className="unsubscribe-image" style={{ width: "175px", height: "42px" }} />
        <h2 style={{ marginTop: "40px" }}>Successfully Unsubscribed</h2>
        <p style={{ marginTop: "20px" }}>You will no longer receive any future communications related to this survey.</p>
        <p>Please allow up to 24 hours for this change to take effect.</p>
      </div>
    </div>
  );
};

export default UnsubscribeSuccess;
