import React, { useEffect, useState } from "react";
import { Button, Result, message, Checkbox, Space } from "antd";
import "./Unsubscribe.scss";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { surveyTakerService } from "./SurveyTaker.service";
import HanoverLogo from "../../assets/images/hanover_logo.png";

function UnsubscribeHandler() {
  const navigate = useNavigate(); 
  const [loading, setLoading] = useState(false);
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [imageUrl, setImageUrl] = useState<string>(""); 

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await surveyTakerService.getImageLogo(token as string); 
        setImageUrl(response.data.logoUrl); 
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage();
  }, []); 

  const handleUnsubscribeEvent = async (globalOptOut: boolean) => {
    try {
      setLoading(true);
      await surveyTakerService.unsubscribe({ token, globalOptOut });
      setUnsubscribed(true);
      message.success(`Successfully unsubscribed`);
      navigate("/unsubscribe-success", { state: { imageUrl } }); 
    } catch (error) {
      message.error("Failed to unsubscribe. Please try again.");
      console.error("Unsubscribe error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (checkedValues: string[]) => {
    setSelectedOptions(checkedValues);
  };

  if (unsubscribed) {
    return (
      <Result
        status="success"
        title="Successfully Unsubscribed"
        subTitle="You have been unsubscribed from receiving further communications."
      />
    );
  }

  return (
    <div className="container">
      <div className="inner-container">
      {imageUrl && <img src={imageUrl} alt="Unsubscribe" className="unsubscribe-image" style={{ width: "175px", height: "42px" }} />}
        <h2>Unsubscribe</h2>
        <p>If you unsubscribe, you won’t receive any future communications related to this survey.</p>
        <p>Are you sure you would like to unsubscribe?</p>
        <div className="unsubscribe-button-container">
          <Button 
            className="unsubscribe-button" 
            type="primary" 
            danger 
            loading={loading} 
            onClick={() => handleUnsubscribeEvent(false)}
          >
            Unsubscribe
          </Button>
        </div>
        <a href="#" onClick={(e) => { e.preventDefault(); handleUnsubscribeEvent(true);}} style={{ display: 'block', color: '#007bff', textDecoration: 'underline', fontSize: '14px', fontWeight: 'bold'}}>
          Unsubscribe from all lists
        </a>
      </div>
    </div>
  );
}

export default UnsubscribeHandler;
